.header {
  height: 110px;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 200px;
}

.logo__link {
  text-decoration: none;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}

.logoImg {
  height: 70px;
}

@media (max-width: 1330px) {
  .container {
    padding: 0 100px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 0 50px;
  }
}

@media (max-width: 768px) {
  .header {
    background-image: linear-gradient(to right, rebeccapurple, rebeccapurple);
  }
  .container {
    padding: 0 25px;
  }
  .logoImg {
    height: 40px;
  }
}
