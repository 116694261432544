.section {
  width: 100%;
  background-color: rgb(255, 231, 255);
  padding-top: 100px;
  padding-bottom: 100px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 0px;
  background-color: #fff;
}

.sectionHeadingBorder {
  width: 200px;
  height: 1px;
  background-color: #000;
  margin: 0 auto 100px;
}

.sectionHeading {
  font-size: 40px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0px auto 50px;
  border: 1px solid #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonialContainer {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  padding: 30px 0;
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}

.products {
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  animation-name: slider;
  animation-duration: 24s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.testimonialContainer:hover .products {
  animation-name: slider;
  animation-play-state: paused;
}

.product {
  width: 300px;
  flex-shrink: 0;
  margin: 0 10px;
}
.product > a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  margin-bottom: 20px;
}

.product > a > .imgBox {
  height: 250px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.product > a > .imgBox .img {
  height: 100%;
  width: auto;
  transition: transform 0.2s ease;
}
.product > a:hover > .imgBox .img {
  transform: scale(1.05);
}

.readBtnBox {
  width: 100%;
}
.readBtn {
  width: 100%;
  padding: 10px 0;
}

.product > p:first-of-type {
  margin-bottom: 30px;
}

.product > p:not(:first-of-type) {
  text-align: center;
}

.name {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}
.name {
  font-size: 17px;
  font-weight: 400;
  color: gray;
}

.readBtn {
  font-size: 16px;
  font-weight: 400;
  margin: 0 auto;
  border: none;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.readBtn:hover {
  text-decoration: underline;
}

.btn {
  border-radius: 50px;
  background-color: transparent;
  outline: none;
  font-size: 30px;
  font-weight: 500;
  padding: 15px 22px;
  border: 1px solid #fff;
  cursor: pointer;
  position: absolute;
  z-index: 25;
  transition: all 0.2s ease;
}
.btn:hover {
  border: 1px solid lightgrey;
  background-color: lightgrey;
}

.next {
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1300px) {
  .testimonialContainer {
    width: 960px;
  }
}
@media (max-width: 1200px) {
  .next {
    right: 50px;
  }
  .prev {
    left: 50px;
  }
}

@media (max-width: 992px) {
  .sectionHeading {
    font-size: 34px;
  }
  .testimonialContainer {
    width: 640px;
  }
}

@media (max-width: 768px) {
  .sectionHeading {
    font-size: 24px;
  }
  .name {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .section {
    padding: 100px 0px 100px;
  }
  .sectionHeading {
    padding-left: 30px;
    padding-right: 30px;
  }
  .container {
    padding: 0px 25px;
  }
  .sectionHeadingBorder {
    margin-bottom: 50px;
  }
  .testimonialContainer {
    width: 320px;
  }
  .name {
    font-size: 16px;
  }
}

@keyframes slider {
  0% {
    transform: translateX(-320px);
    transition: none;
    transition-delay: 4s;
  }
  14% {
    transform: translateX(-320px);
    transition: none;
    transition-delay: 4s;
  }
  16.66% {
    transform: translateX(-640px);
    transition: all 0.75s ease;
    transition-delay: 4s;
  }
  31% {
    transform: translateX(-640px);
    transition-delay: 4s;
  }
  33.33% {
    transform: translateX(-960px);
    transition: all 0.75s ease;
    transition-delay: 4s;
  }
  47% {
    transform: translateX(-960px);
    transition-delay: 4s;
  }
  50% {
    transform: translateX(-1280px);
    transition: all 0.75s ease;
    transition-delay: 4s;
  }
  64% {
    transform: translateX(-1280px);
    transition-delay: 4s;
  }
  66.66% {
    transform: translateX(-1600px);
    transition: all 0.75s ease;
    transition-delay: 4s;
  }
  81% {
    transform: translateX(-1600px);
    transition-delay: 4s;
  }
  83.33% {
    transform: translateX(-1920px);
    transition: all 0.75s ease;
    transition-delay: 4s;
  }
  97% {
    transform: translateX(-1920px);
    transition-delay: 4s;
  }
  100% {
    transform: translateX(-2240px);
    transition: all 0.75s ease;
    transition-delay: 4s;
  }
}
