.section {
  width: 100%;
  background-color: rebeccapurple;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px 30px;
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.footerEl {
  width: 310px;
  max-height: 290px;
  overflow: hidden;
  border-radius: 4px;
}
.footerEl:nth-of-type(2) {
  padding-top: 15px;
}
.logoName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.img {
  height: 60px;
  width: auto;
  margin-right: 5px;
}

.footerText {
  font-size: 17px;
  color: #fff;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1.2px;
  line-height: 1.5;
}

.footerText:not(:last-of-type) {
  margin-bottom: 15px;
}

.name {
  font-size: 28px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
  display: inline-block;
}

.addressBox {
  flex: 1;
}

.icon {
  font-size: 18px;
  display: inline-block;
  width: 30px;
}

.nameSocials {
  margin-bottom: 20px;
  padding-left: 30px;
}

.socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}

.socialIcon {
  font-size: 40px;
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
}

.contactBox {
  flex: 1;
}

.qrLink {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 325px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 4px;
}

.qrLinkImg {
  height: auto;
  max-width: 325px;
  max-height: 300px;
  transform: scale(1.25);
}

.map {
  width: 325px;
  height: 300px;
  border-radius: 4px;
}

@media (max-width: 1200px) {
  .footerEl {
    max-width: 325px;
  }
  .footerEl:nth-of-type(2) {
    padding-top: 10px;
    padding-left: 15px;
  }
  .img {
    height: 60px;
  }
  .footerText {
    font-size: 16px;
    letter-spacing: 1px;
  }
  .name {
    font-size: 26px;
  }
  .nameSocials {
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .footerContent {
    width: auto;
    flex-direction: column;
    column-gap: 0px;
    row-gap: 20px;
  }
  .footerEl {
    max-width: 576px;
    margin: 0 auto;
    width: 425px;
    text-align: center;
  }
  .footerEl:not(:last-of-type) {
    margin-bottom: 40px;
  }
  .logoName {
    justify-content: center;
  }
  .socialIcons {
    justify-content: center;
  }
  .footerEl:nth-of-type(2) {
    padding-top: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 576px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .footerEl {
    width: 375px;
  }
}
@media (max-width: 480px) {
  .footerEl {
    width: 325px;
  }
}
