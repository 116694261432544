.section {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px 30px;
  transition: transform 1s ease, opacity 0.5s ease;
  transition-delay: 0.15s;
}

.container.hidden {
  opacity: 0;
  transform: translateY(300px);
}

.sectionHeading {
  font-size: 46px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  max-width: 325px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s;
}
.sectionHeading:hover {
  text-shadow: 0px 20px 10px rgba(0, 0, 0, 0.25);
  transform: skew(15deg) rotateZ(3deg);
}

.sectionHeadingBorder {
  width: 325px;
  height: 2px;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
  margin: 0 auto;
  margin-bottom: 50px;
}

.imagesContainer {
  width: 100%;
}

.images {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 50px;
}

.image {
  width: 300px;
  height: auto;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.imgBox {
  width: 100%;
  height: 220px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  width: auto;
  height: 100%;
  transform: scale(1);
  transition: all 0.2s ease;
}

.image.inActive > .imgBox > .img {
  transform: scale(0.1);
}

.image:hover .img {
  cursor: pointer;
  transform: scale(1.05);
}

.textBox {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
}

.text {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  color: #fff;
}

@media (max-width: 1350px) {
  .images {
    justify-content: space-evenly;
  }
}

@media (max-width: 1100px) {
  .images {
    row-gap: 30px;
  }
}

@media (max-width: 1024px) {
  .sectionHeading {
    font-size: 40px;
  }
  .images {
    row-gap: 50px;
  }
}

@media (max-width: 768px) {
  .sectionHeading {
    font-size: 34px;
  }
  .sectionHeadingBorder {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .sectionHeading {
    font-size: 24px;
  }
}
