.product {
  width: 300px;
  height: auto;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.imgBox {
  width: 100%;
  height: 220px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  width: auto;
  height: 100%;
  transform: scale(1);
  transition: all 0.2s ease;
}

.product.inActive > .imgBox > .img {
  transform: scale(0.1);
}

.product:hover .img {
  cursor: pointer;
  transform: scale(1.05);
}

.textBox {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
}

.text {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  color: #222;
  color: #fff;
}
