.backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  scroll-behavior: none;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.6);
}

.galleryContainer {
  z-index: 35;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageContainer {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
  max-width: 840px;
  max-height: 600px;
  margin: 0 auto;
}

.img {
  height: auto;
  width: auto;
  max-width: 840px;
  max-height: 600px;
  border: 10px solid #fff;
}

.closeBtn {
  font-size: 24px;
  border: none;
  background-color: rebeccapurple;
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
  color: #fff;
  border-radius: 50px;

  height: 40px;
  width: 40px;
}

@media (max-width: 992px) {
  .galleryContainer,
  .img {
    max-width: 768px;
    max-height: 600px;
  }
}

@media (max-width: 840px) {
  .img {
    width: calc(100vw - 100px);
    height: auto;
    border-width: 5px;
  }
}
