.backdrop {
  position: fixed;
  width: 100%;
  height: 300vh;
  z-index: 10;
}

.mobileNav {
  display: none;
}

.hamburger {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-image: linear-gradient(to left, rebeccapurple, rebeccapurple);
  position: fixed;
  z-index: 28;
  right: 35px;
  top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburger:hover {
  cursor: pointer;
}
.hamburger__line {
  height: 35%;
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  transition: height 0.3s ease;
}
.hamburger:hover .hamburger__line {
  height: 45%;
}
.hamburger__line--top,
.hamburger__line--middle,
.hamburger__line--bottom {
  width: 25px;
  height: 2px;
  background-color: #fff;
  z-index: 30;
}

.hamburger__line--top,
.hamburger__line--bottom {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.hamburger__line--middle {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.hamburger.active .hamburger__line {
  height: 2px;
  transition: height 0.25s ease;
}
.hamburger.active .hamburger__line--top {
  transform: rotate(45deg);
  transition: transform 0.3s ease 0.25s;
}
.hamburger.active .hamburger__line--middle {
  opacity: 0;
  transition: opacity 0.15s ease;
}
.hamburger.active .hamburger__line--bottom {
  transform: rotate(-45deg);
  transition: transform 0.3s ease 0.25s;
}

.navMenu {
  position: fixed;
  top: -120%;
  right: 25px;
  width: 325px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  border: 0.25px solid rebeccapurple;
  padding: 25px;
  transition: all 0.3s ease-in;
  z-index: 20;
}

.navMenu.active {
  top: 100px;
  position: fixed;
  right: 25px;
  width: 325px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 5px;
  padding: 25px;
  transition: all 0.3s ease-out;
  z-index: 20;
}

.menuList {
  list-style-type: none;
}

.menuListItem {
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: rebeccapurple;
  padding-right: 10px;
  cursor: pointer;
}

.menuListItem > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menuListItem:not(:last-of-type) {
  border-bottom: 2px solid rgb(203, 19, 209);
}

@media (max-width: 1100px) {
  .mobileNav {
    display: block;
  }
}
