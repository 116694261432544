.btn {
  height: 50px;
  width: 50px;
  background-image: linear-gradient(
    to top,
    rgb(203, 19, 209),
    rgb(203, 19, 209)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-weight: bold;
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  z-index: 28;
  transition: font-size 0.1s linear;
}
.btn:hover {
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 768px) {
  .btn {
    right: 35px;
    bottom: 35px;
  }
}
