.section {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  min-height: 500px;

  height: 550px;
  position: relative;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 30px 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.imgContainer {
  width: 50%;
  height: 100%;
}

.imgBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.img {
  width: 45%;
  height: auto;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
}
.textContainer {
  width: 50%;
  padding: 50px;
  flex-shrink: 0;
}
.texts {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.title {
  font-size: 66px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.desc {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 400;
  width: 470px;
  line-height: 2;
  letter-spacing: 1.5px;
}

@media (max-width: 1500px) {
  .img {
    width: 55%;
  }
}
@media (max-width: 1400px) {
  .img {
    width: 50%;
  }
}
@media (max-width: 1300px) {
  .title {
    font-size: 60px;
  }
  .desc {
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  .img {
    width: 52%;
  }
  .textContainer {
    padding: 25px;
  }
  .title {
    font-size: 54px;
  }
  .desc {
    font-size: 20px;
    width: 400px;
  }
}

@media (max-width: 1100px) {
  .section {
    height: 450px;
  }
}
@media (max-width: 1024px) {
  .title {
    font-size: 50px;
  }
}

@media (max-width: 992px) {
  .section {
    max-height: 650px;
  }
  .img {
    width: 55%;
  }

  .title {
    font-size: 42px;
  }
  .desc {
    width: 350px;
    font-size: 17px;
  }
}
@media (max-width: 840px) {
  .container {
    align-items: flex-start;
    padding-top: 50px;
  }
  .img {
    width: 60%;
  }
  .textContainer {
    padding: 0px;
  }
  .texts {
    justify-content: flex-start;
  }
  .desc {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .section {
    background-image: linear-gradient(
      to bottom,
      rebeccapurple,
      rgb(203, 19, 209)
    );
    max-height: 800px;
    height: calc(100vh - 165px);
  }
  .container {
    padding-top: 100px;
  }
  .contentContainer {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }
  .imgContainer {
    width: 100%;
    height: 80%;
  }
  .img {
    margin: 0 auto;
    width: auto;
    height: 72%;
  }
  .textContainer {
    width: 100%;
    height: 20%;
    padding: 0px;
  }
  .texts {
    justify-content: center;
  }
  .title {
    font-size: 36px;
    margin: 0 auto 30px;
  }
  .desc {
    margin: 0 auto;
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 640px) {
  .img {
    height: 70%;
  }
}
@media (max-width: 576px) {
  .imgContainer {
    height: 70%;
  }
  .img {
    width: 85%;
    height: auto;
  }
  .textContainer {
    height: 30%;
  }
  .texts {
    transform: translateY(-50px);
  }
  .desc {
    width: 325px;
    margin: 0 auto;
    letter-spacing: 1px;
  }
}

@media (max-width: 476px) {
  .imgContainer {
    height: 65%;
  }
  .textContainer {
    height: 35%;
  }
  .texts {
    transform: translateY(0px);
  }
}
