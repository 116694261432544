.navLinks {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navLinks__list {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.navLinks__list--item {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}
.navLinks__list--item:hover {
  cursor: pointer;
  border-bottom: 3px solid #fff;
}

.linkBtn {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  outline: none;
}

.linkBtn:hover {
  cursor: pointer;
}

.linkIconSpan {
  margin-left: 10px;
}

.linkTxt {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

@media (max-width: 1300px) {
  .navLinks__list {
    width: 450px;
  }

  .linkTxt {
    font-size: 16px;
  }

  .navBtn {
    margin-left: 30px;
    padding: 10px 20px;
    font-size: 16px;
  }
}
@media (max-width: 1250px) {
  .linkTxt {
    font-size: 15px;
  }
  .navBtn {
    margin-left: 25px;
    font-size: 15px;
  }
}
@media (max-width: 1100px) {
  .navLinks {
    display: none;
  }
}
