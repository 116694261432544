.section {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 1440px;
  padding: 100px 50px 0px;
  margin: 0 auto;
  transition: transform 1s ease, opacity 0.5s ease;
  transition-delay: 0.15s;
}

.container.hidden {
  opacity: 0;
  transform: translateY(300px);
}

.sectionHeading {
  font-size: 46px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  max-width: 325px;
  margin: 0 auto 25px;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s;
}
.sectionHeading:hover {
  text-shadow: 0px 20px 10px rgba(0, 0, 0, 0.25);
  transform: skew(15deg) rotateZ(3deg);
}

.sectionHeadingBorder {
  width: 325px;
  height: 2px;
  background-image: linear-gradient(to right, rebeccapurple, rgb(203, 19, 209));
  margin: 0 auto;
  margin-bottom: 25px;
}

.descContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 50px;
}

.descText {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  color: #555;
}
.descText:not(:last-of-type) {
  margin-bottom: 10px;
}

.descBox {
  width: 50%;
  flex-shrink: 0;
  padding: 30px;
}

.highlight {
  font-size: 15px;
  font-weight: 500;
  color: #444;
}

.imgBox {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images {
  width: 500px;
  height: 350px;
  margin: 0 auto;
  position: relative;
}

.img {
  position: absolute;
  width: 270px;
  height: auto;
  transition: all 0.2s;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 5px;
}
.img:hover {
  width: 300px;
  outline: 10px;
  outline-style: solid;
  outline-offset: 20px;
  outline-color: rebeccapurple;
}

.img1 {
  top: 0px;
  left: 0px;
  z-index: 5;
}
.img1:hover {
  z-index: 20;
}
.img2 {
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.img2:hover {
  z-index: 20;
}
.img3 {
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
}
.img3:hover {
  z-index: 20;
}

.categoriesContainer {
  width: 100%;
  margin-bottom: 50px;
}

.categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0 auto;
}

.category {
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  min-width: 275px;
  background-color: #fff;
}

.categoryTextBox {
  padding: 20px;
}
.categoryText {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5px;
  color: #555;
  line-height: 1.75;
  text-align: justify;
}
.categoryText:not(:last-of-type) {
  margin-bottom: 10px;
}

.categoryTextHighlight {
  font-weight: 500;
  color: #444;
}

.textBox {
  width: 100%;
  height: 50px;
  background-color: rebeccapurple;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.visitTextBox {
  margin: 0 auto;
}

.visitText {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #555;
  letter-spacing: 0.5px;
  line-height: 1.8;
  text-align: justify;
}

.visitTextHighlight {
  font-size: 16px;
  color: #444;
  letter-spacing: 1px;
  font-weight: 500;
  color: rebeccapurple;
}

@media (max-width: 1300px) {
  .categories {
    column-gap: 30px;
    row-gap: 50px;
    justify-content: space-evenly;
  }
  .category {
    width: 300px;
  }
}
@media (max-width: 1024px) {
  .sectionHeading {
    font-size: 40px;
  }
  .sectionHeadingBorder {
    margin-bottom: 80px;
  }
  .descContainer {
    flex-direction: column-reverse;
  }
  .descBox {
    width: 100%;
    padding: 0;
  }
  .descText {
    text-align: justify;
  }
  .imgBox {
    width: 100%;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .sectionHeading {
    font-size: 34px;
  }
  .sectionHeadingBorder {
    width: 200px;
  }
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .descContainer {
    width: 576px;
    margin: 0 auto 50px;
  }
  .categories {
    justify-content: center;
    padding-left: 35px;
    padding-right: 35px;
  }
  .visitText {
    padding: 0 50px;
  }
}
@media (max-width: 640px) {
  .descContainer {
    width: 476px;
  }
  .images {
    height: 300px;
    width: 450px;
  }
  .img {
    width: 240px;
  }
  .img:hover {
    width: 270px;
  }
}

@media (max-width: 576px) {
  .sectionHeading {
    font-size: 24px;
  }
  .descContainer {
    width: 425px;
  }
  .images {
    height: 250px;
    width: 350px;
  }
  .img {
    width: 210px;
  }
  .img:hover {
    width: 240px;
    outline-offset: 15px;
  }
  .categories {
    padding-left: 40px;
    padding-right: 40px;
  }
  .visitText {
    padding: 0 40px;
  }
}

@media (max-width: 476px) {
  .descContainer {
    width: 325px;
  }
  .images {
    height: 280px;
    width: 325px;
  }
  .img {
    width: 210px;
  }
  .img:hover {
    width: 220px;
    outline-offset: 10px;
  }
}
